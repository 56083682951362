<template>
  <div class="row">
    <h1 class="m-b-md">Documentation</h1>
    <p>Coming soon...</p>
  </div>
</template>

<script></script>

<style></style>
